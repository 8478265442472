<template>
  <a-modal
    width="1200px"
    v-model="isShow"
    title="订单详情"
    :mask-closable="false"
    :body-style="{ paddingLeft: 0 }"
  >
    <a-row type="flex" :gutter="24">
      <a-col flex="200px">
        <a-menu
          class="left-menus"
          mode="vertical"
          :default-selected-keys="[currentComponent]"
          @select="handleSelectMenu"
        >
          <a-menu-item
            v-for="item in tabs"
            :key="item.component"
          >
            <a-icon :type="item.icon" />
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </a-col>

      <a-col flex="990px" class="right-content">
        <keep-alive>
          <component
            :is="currentComponent"
            :id="id"
            :service-order-id="recordId"
            :order-type="orderType"
            ref="child"
          />
        </keep-alive>
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button type="primary" @click="closeModal">关闭</a-button>
      <a-button type="primary" @click="updateModal">更新</a-button>
    </template>

    <!--操作历史 -->
    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="serviceOrderId"
      :reference-type="historyOrderType"
      :visible.sync="isShowOperationHistoryModal"
    />
  </a-modal>
</template>

<script>

export default {
  name: 'ShowDevice',
  components: {
    BasicInformation: () => import('@/views/orders/system/BasicInformation'),
    SubscribeBasicInformation: () => import('@/views/orders/system/SubscribeBasicInformation'),
    DeceasedInfo: () => import('@/views/orders/system/deaths/DeceasedInfo'),
    PackageInfo: () => import('@/views/orders/system/PackageInfo'),
    OtherProjects: () => import('@/views/orders/system/other_projects/index'),
    TaskList: () => import('@/views/orders/system/TaskList'),
    SubscribeOrderProduct: () => import('@/views/orders/system/SubscribeOrderProduct'),
    OperationHistory: () => import('@/views/orders/system/OperationHistory'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    FamilyNotification: () => import('@/views/orders/system/family_notification/index')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    recordId: {
      type: Number,
      default: function() {
        return 0
      }
    },
    orderType: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      device: {},
      attribute: 'basic_information',
      isShowOperationHistoryModal: false,
      historyOrderType: '',
      orderId: this.id,
      serviceOrderId: 0,
      currentComponent: 'BasicInformation',
      tabs: [],
      serviceOrderTabs: [
        { component: 'BasicInformation', name: '基本信息', icon: 'profile' },
        { component: 'DeceasedInfo', name: '逝者信息', icon: 'laptop' },
        { component: 'PackageInfo', name: '套餐信息', icon: 'inbox' },
        { component: 'OtherProjects', name: '其他项目', icon: 'inbox' },
        { component: 'TaskList', name: '任务列表', icon: 'inbox' },
        { component: 'OperationHistory', name: '操作历史', icon: 'history' },
        { component: 'FamilyNotification', name: '家属告知书', icon: 'inbox' }
      ],
      subscribeOrderTabs: [
        { component: 'SubscribeBasicInformation', name: '基本信息', icon: 'profile' },
        { component: 'SubscribeOrderProduct', name: '产品信息', icon: 'inbox' },
        { component: 'TaskList', name: '任务列表', icon: 'inbox' },
        { component: 'OperationHistory', name: '操作历史', icon: 'history' }
      ]
    }
  },
  created() {
    this.fetchTabs()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    showHistoryOrder() {
      this.serviceOrderId = this.recordId
      if (this.orderType === 'service') {
        this.historyOrderType = 'ServiceOrder'
      } else if (this.orderType === 'subscribe') {
        this.historyOrderType = 'SubscribeOrder'
      } else {
        this.historyOrderType = 'AdditionalItemOrder'
      }
      this.isShowOperationHistoryModal = true
    },
    fetchTabs() {
      if (this.orderType === 'service') {
        this.tabs = this.serviceOrderTabs
      } else {
        this.tabs = this.subscribeOrderTabs
        this.currentComponent = 'SubscribeBasicInformation'
      }
    },
    handleSelectMenu({ item, key, selectedKeys }) {
      if (key === 'OperationHistory') {
        this.orderId = this.recordId
      } else {
        this.orderId = this.id
      }
      this.currentComponent = key
    },
    closeModal() {
      this.$emit('completed')
      this.isShow = false
    },
    updateModal() {
      this.$refs.child.fetchData()
      this.$warning({
        title: '更新成功',
        content: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.left-menus {
  height: 110%;
  background:#e8e8e8;
  margin-top: -23px;
}

.right-content {
  height: 500px;
  overflow: auto;
}

.left-menus /deep/ .ant-radio-button-wrapper {
    height: 50px;
    width: 160px;
    padding: 0;
    border: none;
    line-height: 48px;
    display: block;
    text-align: center;
}
</style>
